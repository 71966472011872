import "styles/pages/page-blog.scss";

import React from "react";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";

import Breadcrumps from "components/Breadcrumps";

import { Content } from "page_components/blog";

const PageBlog = ({ pageContext }) => {
  const isFirst = pageContext.current_page === 1;
  const isLast = pageContext.current_page === pageContext.num_pages - 2;
  const prevPage =
    pageContext.current_page - 1 === 1
      ? pageContext.uri
      : `${pageContext.uri}page/${(pageContext.current_page - 1).toString()}`;
  const nextPage = (pageContext.current_page + 1).toString();
  const uri = pageContext.uri;

  const blogData = {
    isFirst,
    isLast,
    prevPage,
    nextPage,
    uri,
  };

  return (
    <Layout>
      <Seo title="Blog" {...blogData} />
      <Breadcrumps data="Blog" />
      <SubpageHeader title="Blog" />
      <Content pageContext={pageContext} />
    </Layout>
  );
};

export default PageBlog;
