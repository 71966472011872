import "./news-card.scss";
import React from "react";
import PostAuthor from "components/PostAuthor";
import PostDate from "components/PostDate";
import PostCategories from "components/PostCategories";
import Divider from "components/Divider";
import { Link } from "gatsby";
import LazyLoad from "react-lazy-load";

const NewsCard = ({
  title,
  excerpt,
  categories,
  authorName,
  authorAvatar,
  date,
  thumbnail,
  url,
}) => {
  return (
    <div className="news-card">
      <Link to={url}>
        <h2 className="news-card__title">{title}</h2>
      </Link>
      <Divider marginTop={15} marginBottom={45} />
      <div className="row">
        <div className="col-md-3">
          <Link to={url}>
            <LazyLoad>
              <div
                className="news-card__thumbnail"
                style={{ backgroundImage: `url(${thumbnail})` }}
              />
            </LazyLoad>
          </Link>
        </div>
        <div className="col-md-9">
          <div className="news-card__content">
            <Link to={url}>
              <div
                className="news-card__text"
                dangerouslySetInnerHTML={{ __html: excerpt }}
              />
            </Link>
            <Link to={url} className="arrow-link">
              Czytaj dalej
            </Link>
            <Divider marginTop={30} marginBottom={15} />
            <div className="news-card__author-date-row">
              <PostAuthor avatar={authorAvatar} name={authorName} />
              <PostDate date={date} />
            </div>
            <PostCategories categories={categories} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;
