import "./pagination.scss";

import React from "react";
import { Link } from "gatsby";

const Pagination = ({ current_page = 1, num_pages = 1, uri }) => {
  const isFirst = current_page === 1;
  const isLast = current_page === num_pages - 2;

  const prevPage =
    current_page - 1 === 1
      ? uri
      : `${uri}page/${(current_page - 1).toString()}`;
  const nextPage = (current_page + 1).toString();

  return (
    <div className="pagination">
      {!isFirst && (
        <>
          <Link to={prevPage} className="pagination__prev">
            <div className="pagination__arrow pagination__arrow--prev"></div>
          </Link>
          <div className="pagination__num-container">
            <Link to="/blog/" className="pagination__number">
              1
            </Link>
          </div>
          {current_page !== 2 && (
            <div className="pagination__num-container">...</div>
          )}
        </>
      )}
      {current_page !== 1 && current_page !== 2 && (
        <div className="pagination__num-container">
          <Link
            to={`${uri}page/${current_page - 1}/`}
            className="pagination__number"
          >
            {current_page - 1}
          </Link>
        </div>
      )}
      <div className="pagination__num-container">
        <div className="pagination__number pagination__number--current">
          {current_page}
        </div>
      </div>
      {current_page !== num_pages - 3 && current_page !== num_pages - 2 && (
        <div className="pagination__num-container">
          <Link
            to={`${uri}page/${current_page + 1}/`}
            className="pagination__number"
          >
            {current_page + 1}
          </Link>
        </div>
      )}
      {!isLast && (
        <>
          {current_page !== num_pages - 3 && (
            <div className="pagination__num-container">...</div>
          )}
          <div className="pagination__num-container">
            <Link
              to={`${uri}page/${num_pages - 2}/`}
              className="pagination__number"
            >
              {num_pages - 2}
            </Link>
          </div>
          <Link to={`${uri}page/${nextPage}/`} className="pagination__next">
            <div className="pagination__arrow pagination__arrow--next"></div>
          </Link>
        </>
      )}
    </div>
  );
};

export default Pagination;
